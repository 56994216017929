import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import Header from './Header';
import Footer from './Footer';
import { NavLink } from 'react-router-dom';
const MyVideosBasket = () => {
    const [newsData, setNewsData] = useState([]);
    const [search, setSearch] = useState('')
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageList, setPageList] = useState([1,2,3]);
    const [lastPage, setLastPage] = useState(10);
    const [nextBtnStatus, setNextBtnStatus]=useState(false)
    const [prevBtnStatus, setPrevBtnStatus]=useState(false)
    const inputRef = useRef(null)
    const handleRemoveImpLink = ()=>{

        axios({
          method: "get",
          url: `https://stingers.ianswire.in/api/reset_mark_important/`,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${localStorage.accessToken ? localStorage.accessToken :''}`,
            
          },
        
        })
          .then((res) => {
            fetchData(currentPage, localStorage.langg);
          })
          .catch((error) => {
          
            console.error("Error uploading image: ", error);
          });
}
    const handleNextPage = () => {
        if(nextBtnStatus){
          setCurrentPage(currentPage + 1);
          let newArry = pageList;
          let newAddArray = pageList[pageList.length-1]+1;
          // if(currentPage > 2){
            let newRemoveArray = pageList[0];
          //
          // }
          newArry.shift(newRemoveArray);
          newArry.push(newAddArray);
            setPageList(newArry);
            }
      }
          
         
      const handlePrevPage = () => {
      if(currentPage > 1){
        setCurrentPage(currentPage - 1);
        // setCurrentPage(currentPage + 1);
        let newArry = pageList;
        let newAddArray = pageList[pageList.length-1];
        let newRemoveArray = pageList[0]-1;
          newArry.unshift(newRemoveArray);
        newArry.pop(newAddArray);
      setPageList(newArry);
      }
      }
    
    
      const handleStartPage = () => {
      // if(requestFlag){
        setCurrentPage(1);
        let lastArrtty = [1, 2, 3]
        setPageList(lastArrtty);
      //  }
      }
    const fetchData = (page, ln)=>{
        const headers = {
            "Content-Type": "multipart/form-data",
            "Authorization": `Token ${localStorage.accessToken ? localStorage.accessToken : ''}`, // Ensure correct header
        };

        // Fetch data from the API when the component mounts
        let apiUrl = `https://stingers.ianswire.in/api/videos/?created_by=${localStorage.userName ? localStorage.userName : ''}&page=${currentPage}&query=`
        if(inputData.title){
            apiUrl = `${apiUrl}${inputData.title}`
          }
        axios.get(apiUrl, { headers })
            .then(response => {
                setNewsData(response.data.results);
                if(response.data.next){
                    setNextBtnStatus(true)
                }else{
                    setNextBtnStatus(false)
                }
                if(response.data.previous){
                    setPrevBtnStatus(true)
                }else{
                    setPrevBtnStatus(false)
                }
                setLastPage(response.data.count)
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            });
    }
    const handleChange = event=>{
        const {name, value} =event.target;
        setInputData({ ...inputData, [name]: value })
        setCurrentPage(1)
    
      }
      const [inputData, setInputData] = useState({
        title: ''
      })
    useEffect(() => {
        // Set up headers
        fetchData(currentPage, localStorage.langg);

    }, [currentPage]);

    // if (loading) {
    //     return <p>Loading...</p>;
    // }

    // if (error) {
    //     return <p>Error loading data: {error.message}</p>;
    // }

    return (
        <>
    <div className="wrapper">
        <Header />
            <div className="content-wrapper">
                <section className="section dashboard">
                    <div className="row">
                        <div className="col-lg-12">

                            <div className="row">
                            {  !loading && 
                                <div className="card recent-sales overflow-auto">
                                    <div className="card-body">
                                    <div className="row stn-search-hed">
                                            <div className="col-lg-6 d-flex align-items-center ev-stn">
                                                <h5 className="card-title user-b"><img className="nav-i-icon" src="/assets/icons/stn-user-b.png"/> <span className='name-u'>{localStorage && localStorage.length > 0 && localStorage.userName ? localStorage.userName.toUpperCase():''} </span> <span className='name-b'>Bucket</span> </h5>
                                            </div>
                                            
                                            <div className="col-lg-4">
                                                <div className="search-form-allsnt">
                                                    <form onSubmit={(event)=>{event.preventDefault();fetchData(1,'')}}>
                                                        <input type="search" ref={inputRef} placeholder="Search...." name="title" value={inputData.title} onChange={handleChange}/>
                                                        <span className="input-group-text border-0" onClick={()=>{
                                                fetchData(1,'')
                                                }}><i className="fas fa-search" aria-hidden="true"></i></span>
                                                    </form>
                                                </div>
                                            </div>
                                            {  localStorage && localStorage.length > 0 &&  localStorage.langg && localStorage.langg=='All content' &&
                                            <div className="col-lg-2 align-end">
                                                <div className="search-form-allsnt">
                                                <button className="btn btn-reset-mark" type="button" onClick={handleRemoveImpLink}>Reset Important <i className="fas fa-undo"></i></button>
                                                </div>
                                            </div>
                                            }
                                        </div>
                                        <div id="no-more-tables">
                                            <table className="col-md-12 table table-bordered table-striped table-condensed cf">
                                                <thead className="cf">
                                                    <tr>
                                                        <th>ID</th>
                                                        <th>Heading</th>
                                                        <th className="numeric">Created Date</th>
                                                        <th className="numeric">Created by</th>
                                                        <th className="numeric">Accepted by</th>
                                                        <th className="numeric">Status</th>
                                                        <th className="numeric">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {newsData.map((newsItem) => (
                                                        <tr key={newsItem.id}>
                                                            <td data-title="ID">{newsItem.id}</td>
                                                            <td data-title="Heading">{newsItem.title}</td>
                                                            <td data-title="Created Date" className="numeric">
                                                                {new Date(newsItem.created_at).toLocaleString()}
                                                            </td>
                                                            <td data-title="Created by" className="numeric">{newsItem.created_by }</td>
                                                            <td data-title="Accepted by" className="numeric">{newsItem.accepted_by}</td>
                                                            <td data-title="Status" className="numeric">
                                                                {newsItem.status ? (
                                                                    <span className={`badge ${getStatusClass(newsItem.status)}`}>
                                                                        {newsItem.status}
                                                                    </span>
                                                                ) : ''}
                                                            </td>
                                                            <td data-title="Action" className="numeric">
                                                        

                                                                    <NavLink to={'/edit-stn-video/' + newsItem.id} className="btn btn-sm btn-primary mx-1">
                                                                    <i className="fas fa-edit viewedit" aria-hidden="true"></i>
                                                                    </NavLink>  

                                                            
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                            {/* Pagination (You can customize pagination functionality here) */}
                                            <div className="pagination">
                                                <ul>
                                                    {currentPage == 1  && <li onClick={handleStartPage} disabled={currentPage === 1}><NavLink className="prevnext disablelink" to="#">Previous</NavLink></li>}
                                                    {currentPage != 1  &&<li onClick={handlePrevPage} ><a href="#">Previous</a></li>}
                                                    {pageList.map((data, i)=>{
                                                    return(
                                                    <li key={i} onClick={()=>{
                                                    setCurrentPage(data)}}><a href="#" className={currentPage == data ? 'active' : ''}>{data}</a></li>
                                                        )
                                                    })}
                                                    <li onClick={handleNextPage}><a href="#" className="prevnext">next</a></li>
                                                </ul>                                            							
                                            </div> 
                                        </div>
                                    </div>
                          

                                </div>
                            }
                                {loading && 
                                <div className="card-body">
                                    <div className="load-img">
                                        Loading...
                                        <div className="loader"></div>
                                    </div>  
                                </div>  
                            }
                            </div>
                        </div>
                    </div>
                </section>
            </div>

                            
            <Footer />
        </div>
        </>
    );
};

// Helper function to determine badge color based on status
const getStatusClass = (status) => {
    switch (status) {
        case 'approved':
            return 'bg-success';
        case 'on_hold':
            return 'bg-warning';
        case 'rejected':
            return 'bg-danger';
        default:
            return 'pending_snt';
    }
};
const handleView = (id) => {
    // Replace this with actual view functionality
    console.log(`Viewing news item with ID: ${id}`);
};

// Function to handle edit button click
const handleEdit = (id) => {
    // Replace this with actual edit functionality
    console.log(`Editing news item with ID: ${id}`);
};

export default MyVideosBasket;
