import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Header from './Header'
import Footer from './Footer'
import {Helmet} from "react-helmet";
import { useHistory, NavLink } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Multiselect from 'multiselect-react-dropdown'; // Adjust according to your Multiselect library
import API_URL from './ApiData'
import {useParams} from 'react-router-dom';
import copy from "copy-to-clipboard"
import { format, parseISO } from 'date-fns'
  const EditNewsStn = () => {
  const history = useHistory()
  const {editId} = useParams();
  const [stringSearch, setStringSearch] = useState('')
  const handleChange = event=>{
  const {name, value} =event.target;
    setInputData({ ...inputData, [name]: value });
    if(name=='language' && value){
      //alert()
      if(value == 'en'){
        setStringSearch('english_news')
        setSearch('')
        getStringerData('english_news','')
      }else if(value == 'hi'){
        setStringSearch('hindi_news')
        setSearch('')
        getStringerData('hindi_news','')
      }
      
    }
    }
  const handleOnChange = event => {
  const { name, value } = event.target;
      setInputData({ ...inputData, [name]: value });
    }
  const [readOn, setReadOn] = useState(false)
  const [conData, setValue] = useState('');
  const [markStatus, setMarkStatus]=useState(false)
  const handleChecked = (event) => {
    setMarkStatus(event.target.checked);
  };
  const [inputData, setInputData] = useState({
    id: null,
    title: '',
    description: '',
    images: [],
    status: "",
    stringers:'',
    language: '',
    created_by: "",
    accepted_by: "",
    remarks: "",
    mark_important:false,
    created_at: ""
  })
  const formatDate = (timestamp)=>{
    const date = parseISO(timestamp)
    return format(date, 'MMMM dd, yyyy h:mm a')
}
  const fetchData = async () => {
    setIsload(true)
      axios(
         {method: "get",
          url: `${API_URL.CREATE_NEWS}${editId}/`,
          headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": `Token ${localStorage.accessToken ? localStorage.accessToken : ''}`, // Ensure correct header
          },
        
        })
      .then((res) => {
          if(res && res.data){
            setInputData({
              title: res.data.title,
              description: res.data.description,
              status: res.data.status,
              language: res.data.language,
              created_by: res.data.created_by,
              stringers:res.data.stringers && res.data.stringers.length > 0 ? res.data.stringers:[],
              accepted_by:res.data.accepted_by,
              remarks: res.data.remarks,
              images:res.data.images,
              created_at: res.data.created_at ? formatDate(res.data.created_at): '',
            })
            if(res.data.language){
              if(res.data.language == 'en'){
                setStringSearch('english_news')
                getStringerData('english_news','')
              }else if(res.data.language == 'hi'){
                setStringSearch('hindi_news')
                getStringerData('hindi_news','')
              }
            }
            if(res.data.mark_important){
              setMarkStatus(true)
            }
            setValue(res.data.description)
            if(res.data.images && res.data.images.length > 0){
              setImgAry(res.data.images)
            }

            if(res.data.remarks){
              setRemoveCont(res.data.remarks)
            }
            setIsload(false)
            
          }
          
      });
     // setIsLoading(false)
  }

  // Usage

  const [isAccept, setIsAccept] = useState(false)
  const handleSubmit =  (type) => {
   // e.preventDefault();
   setIsload(true)
    if(!inputData.title){
      alert('Title can not be blank.');
      return false;
    }else if(!inputData.description){
      alert('Description can not be blank.');
      return false;
    }

    const temp = imgArry.map(image => image.id);

    let tempdata = {
      id: inputData.id,
      title: inputData.title,
      description: conData,
      image_ids: temp,
      stringers: inputData.stringers && inputData.stringers.length > 0 ? [parseInt(inputData.stringers)]: [],
      status: type== 'hold' ? 'on_hold' : type== 'pending' ? 'pending': type== 'submitted' ? 'submitted' :'',
      language: inputData.language,
      // created_by: inputData.created_by,
      // accepted_by: inputData.accepted_by,
      remarks: inputData.remarks,
      mark_important:markStatus,
      updated_at: getCurrentTimeInIST(),

    }
    let tempAccptdata = {
      id: inputData.id,
      title: inputData.title,
      description: conData,
      image_ids: temp,
      mark_important:markStatus,
      stringers: inputData.stringers && inputData.stringers.length > 0 ? [parseInt(inputData.stringers)]: [],
      status: 'approved',
      language: inputData.language,
      // created_by: inputData.created_by,
      accepted_by: localStorage && localStorage.length > 0 && localStorage.userName ? localStorage.userName : '',
      remarks: inputData.remarks,
      updated_at: getCurrentTimeInIST(),

    }
    let tempRejectdata = {
      id: inputData.id,
      title: inputData.title,
      description: conData,
      image_ids: temp,
      mark_important:markStatus,
      stringers: inputData.stringers && inputData.stringers.length > 0 ? [parseInt(inputData.stringers)]: [],
      status: 'rejected',
      language: inputData.language,
      // created_by: inputData.created_by,
      accepted_by: localStorage && localStorage.length > 0 && localStorage.userName ? localStorage.userName : '',
      remarks: inputData.remarks,
      updated_at: getCurrentTimeInIST(),

    }
    axios({
      method: "put",
      url: `${API_URL.CREATE_NEWS}${editId}/`,
      data: type && type == 'approved' ? tempAccptdata : type == 2 ? tempRejectdata : tempdata,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${localStorage.accessToken ? localStorage.accessToken : ''}`,
      },
    }).then((response) => {
      if (response.status === 200) {
        setIsload(false)
        console.log('Success:', response.data);
        if(type && type !=4){
         // history.push('/all-photos-stn'); // Redirect to the home page on success  (localStorage.langg=='ce' || localStorage.langg=='hindi' || 
         // history.push('/all-news'); // Redirect to the home page on success
         if(localStorage && localStorage.length > 0 && localStorage.langg && localStorage.langg && localStorage.langg=='All content' && type !='pending'){
          history.push('/all-news'); // Redirect to the home page on success
         }else if(localStorage && localStorage.length > 0 && localStorage.langg && localStorage.langg && localStorage.langg=='hindi' && type !='pending'){
          history.push('/all-hindi-news'); // Redirect to the home page on success
        }else if(localStorage && localStorage.length > 0 && localStorage.langg && localStorage.langg && localStorage.langg=='hindi' && type !='pending'){
          history.push('/all-english-news'); // Redirect to the home page on success          
        }else{
          //history.push('/my-news'); // Redirect to the home page on success
          if(type !='pending'){
            history.push('/my-news'); // Redirect to the home page on success
          }
        }
        }
      }
    })
  }

  const formatDateTime = () => {
    const dateString = new Date();
    const date = new Date(dateString);
  
    // Extracting parts of the date
    const day = String(date.getDate()).padStart(2, '0'); // Get day and pad it to 2 digits
    const month = date.toLocaleString('default', { month: 'short' }); // Get month as short name
    const year = date.getFullYear(); // Get full year
  
    // Formatting the time
    const options = {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    };
    const timeString = new Intl.DateTimeFormat('en-US', options).format(date);
  
    return `${day} ${month} ${year}, ${timeString}`;
  };
  const emailAfter =(ty)=>{
    setIsload(true)
    handleSubmit(ty)
  //   let tempDesc ="";
  //   const tempDiv = document.createElement('div')
  //   tempDiv.innerHTML = conData
 
  //   // Remove most HTML tags except for <p>
  //   let copyt = ''
 
  //   // Replace specific <p> tags with double line breaks
  //   //copy = copy.replace(/<p><\/p>/g, '\n\n');
 
  //   // Keep specific <p> tags at the bottom
  //   const specificTags = tempDiv.querySelectorAll('p')
  //   specificTags.forEach((tag) => {
  //      copyt += '\n\n' + tag.innerHTML;
  //   });
 
  //   // Combine title, short_desc, and modified content
  //   let final_copy = '';
  //   //if(final_copy){
  //     //  final_copy = `${inputData.title}\n\n ${copyt}`
  //     final_copy = `${copyt}`

  //      final_copy=final_copy.replace("<br>","\n")
  //      final_copy=final_copy.replace('<br>','\n')
  //      final_copy = final_copy.replace(/&nbsp;/g, ' ')
  //      final_copy=final_copy.replace('<br data-mce-bogus="1">','')

  //   let nTp ="";
  //   let nTc="";
  //   if(ty=='approved'){
  //     nTp = "Accepted_By : ";
  //     nTc = "Accepted_at : ";
  //   }
  //   if(ty==2){
  //     nTp = "Rejected_By : ";
  //     nTc = "Rejected_at : ";

  //   }
  //   var currenteDate = formatDateTime(); 

  //   tempDesc = nTc+currenteDate+"\r\n \r\n"+nTp+ localStorage.userName+"\r\n \r\n"+"Description : "+ final_copy;


    
  // let eData =
  //   {
  //     "title": inputData.title,
  //     "description": tempDesc,
  //     "Stringer_name":localStorage && localStorage.length > 0 && localStorage.userName ? localStorage.userName : "",
  //   }
  
  //   // axios({
  //   //   method: "post",
  //   //   url: `http://172.16.1.56:8001/api/email-before-edit/`,
  //   //   data: eData,
  //   //   headers: {
  //   //     'Content-Type': 'application/json',
  //   //     Authorization: `Token ${localStorage.accessToken ? localStorage.accessToken : ''}`,
  //   //   },
  //   // }).then((response) => {
  //   //   if (response.status === 200) {
  //   //     console.log('Success:', response.data);
  //   //     setIsload(false)
  //   //     // if(type && type !=4){
  //   //     //   history.push('/all-photos-stn'); // Redirect to the home page on success

  //   //     // }
  //   //     handleSubmit(ty)
  //   //   }
  //   // })
  }
const [strinData, setStringData] = useState()
const [optionsTY, setOptionsTY] =useState()
const inputSData=(event)=>{
  setSearch(event.target.value);
  getStringerData(stringSearch, event.target.value)
} 
const [inputSearch, setSearch] = useState()
const inputRef = useRef(null)

    const getStringerData = (type, search) =>{
            let imSearchAPI = `https://stingers.ianswire.in/api/stringers/?stringer_type=${type}`;
            if(search){
                imSearchAPI = imSearchAPI+`&search=${search}`
            }
            axios.get(
            imSearchAPI,
            {
                headers: {
                Authorization: `Bearer ${localStorage.accessToken ? localStorage.accessToken :''}`,
                },
            }
            ).then((res) => {
            const allStrngData =
                res.data.results &&
                res.data.results.length > 0 &&
                res.data.results.map((item) => {
                return {
                account_no: item.account_no,
                district : item.district,
                email : item.email,
                id : parseInt(item.id),
                ifsc_code : item.ifsc_code,
                name: item.name,
                number : item.number,
                state: item.state,
                uniqueID : item.uniqueID
                    
                };
                });
            if(res && res.data.results && res.data.results && res.data.results.length > 0 ){
            setStringData(allStrngData);
            const optionsvvTY = allStrngData.map(person => ({
                value: person.id,
                label: person.name
              }));
              setOptionsTY(optionsvvTY);
            // console.log('vbb',imgSearchD);
            }else{
            setStringData()
            }

            //console.log('TMT-varun', res);
            });
        
    }
  const [isload, setIsload] = useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const [imgModal, setImgModal] = useState(false);
  const [msg, setMsg]= useState('')
  const [imgArry, setImgAry] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const handleFileChange = (e) => {
    if(e && e.target && e.target.files[0]){
      if(e.target.files[0].type != "image/jpeg" && e.target.files[0].type != "image/jpg"){
        alert(`${e.target.files[0].type}  not be allowed.`)
        e.target.value = null;
      }
    }
    const files = e.target.files;
    setSelectedFiles([...files]); // Use the spread operator to create a new array
    
  };
  const uploadImage = (e) => {
    e.preventDefault();
    const formData = new FormData();
    // Loop through selected files and upload them one by one
      for (let i = 0; i < selectedFiles.length; i++) {
        formData.append('image', selectedFiles[i]);
      }
      console.log(formData, 'formDataa')

        setIsLoading(true)
        axios({
          method: "post",
          url: `${API_URL.CREATE_NEWS}upload-image/`,
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${localStorage.accessToken ? localStorage.accessToken :''}`,
            

          },
        
        })
          .then((res) => {
            if(res && res.data && res.data && res.data.uploaded_images && res.data.uploaded_images.length > 0){
                const newImage =
                        res.data &&
                        res.data.uploaded_images &&
                        res.data.uploaded_images.length > 0 &&
                        res.data.uploaded_images.map((item) => {
                        return {
                          id:item.id ? item.id : "",
                          image: item.image_url ? item.image_url : "",
                        }})
              // Add the new image to the image array
              if(imgArry && imgArry.length > 0){
                // let bewwww = imgArry.push(newImage);
                // setImgAry((imgArry) => [newImage, ...imgArry]);
                const mergedArray = imgArry.concat(newImage);
                setImgAry(mergedArray)
              }else{
                setImgAry(newImage);
                
              }
              handleSubmit(4)
              setIsLoading(false)
              setImgModal(false)
              console.log(imgArry, 'mukesh')
            }
          })
          .catch((error) => {
            setIsLoading(false)
            console.error("Error uploading image: ", error);
          });
      
      // Clear the form input fields and selected files

      setSelectedFiles([])
  }
  const [removeCont, setRemoveCont] = useState('')
  const removeImg = (id)=>{
   // alert(id)
    const newImgArry = imgArry.filter(item => item.id !== id)
    if(!removeCont){
      setRemoveCont('Deleted id -'+id)
    }else{
      setRemoveCont(removeCont + ', '+id)
    }
    
    setImgAry(newImgArry)
  }
  const getCurrentTimeInIST = () => {
    const now = new Date();
    // Calculate IST by adding 5 hours and 30 minutes (in milliseconds)
    const istTime = new Date(now.getTime() + (5.5 * 60 * 60 * 1000));
    // Format the date to 'YYYY-MM-DD HH:mm:ss'
    const formattedDate = istTime.toISOString().replace('T', ' ').substring(0, 19);
    return `${formattedDate} +05:30`; // Append the IST offset
};
  useEffect(() => {
    // Fetch tags
    // if(localStorage && localStorage.length > 0 && (localStorage.videoOpt || localStorage.editor)){
    //   setReadOn(false)
    // }
    if(localStorage && localStorage.length > 0 && localStorage.publishOpt && localStorage.publishOpt == 'publish'){
      setIsAccept(true)
    }
    fetchData()
    //getStringerData('english_news')
 
  }, [editId, msg]);
  return (
    <>
<Helmet>
  <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
  <link rel="icon" href="icon-new.ico" type="image/x-icon" />
  <link rel="shortcut icon" href="/theme/Ianstv/images/favicon.ico" type="image/x-icon" />
  <meta data-meta-title="Ianstv.in" name="keywords" content="Ianstv news, Ianstv updates, Ianstv celebrities, Hindi cinema, Indian film industry, Ianstv gossips, Ianstv rumors, Ianstv releases, Movie reviews, Celebrity interviews" />
  <meta data-meta-title="Ianstv.in" name="description" content="Stay up-to-date with the latest Ianstv news and updates. Get the hottest gossips, rumors, movie reviews, and exclusive celebrity interviews from the captivating world of Hindi cinema. Your one-stop destination for everything Ianstv." />
</Helmet>
  <div className="wrapper">
  <Header />
  <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-10">
                <h1 className="m-0">Edit News</h1>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <div className="col-lg-12">
            {!isload &&
              <div className="card card-outline card-primary">
                <div className="card-body">
                  <form>
                    <div className="form-row">
                      {/* <div className="form-group col-md-4" style={{marginLeft:'20px'}}>
                              <input
                                type="checkbox"
                                checked={markStatus}
                                onChange={handleChecked}
                                className="form-check-input"
                              />
                              <label className="inputHeading" htmlFor="gridCheck"> Mark Important: *{markStatus} </label>
                        </div>  */}
                      {/* <div className="form-group col-md-12">
                        <label htmlFor="inputHeading">Title: *</label>
                        <input
                          type="text"
                          name="title"
                          className="form-control"
                          id="inputHeading"
                          value={inputData.title}
                          onChange={handleChange}
                          readOnly ={readOn}
                        />
                      </div> */}
                    </div>

                    <div className="d-md-flex">
                        <div className="w-100">
                            <div className="form-group">
                                <label>Main Content *</label>
                                <div className='video-editor'>
                                  <ReactQuill className="form-control form-control-user editShortDesc" 
                                  theme="snow" 
                                  type="text"
                                  name="content" 
                                  value={conData} 
                                  onChange={setValue}
                                  readOnly ={readOn}
                                  />
                                </div>                                
                            </div>
                        </div>
                    </div>
                                                                          
                <section className="content">
                    <div className="container-fluid">
                      <div className="col-lg-12">
                        <div className="card card-outline card-primary">
                          <div className="card-body">
                    {  !isload &&                  
                            <div className="col-md-12">																	
                              <div className="accordion" id="morePhoto">
                                <button className="addimg-btn btn btn-primary" type="button" onClick={()=>{
                                    setImgModal(!imgModal)
                                  }}>
                                + Add Images
                                </button>
                              {isLoading     && 
                                <div className="load-img">
                                  Uploading...
                                  <div className="loader"></div>
                                </div>  
                              }	
                                <div className="accordion" id="morePhoto">
                          {imgModal &&
                            <div>
                              <div className="card card-body">
                              <form encType="multipart/form-data" className="border p-2 mb-2 rounded">
                                    <div className="form-row">
                                    <div className="form-group col-md-2">
                                      <label htmlFor="files">Photos</label>
                                      <input
                                        type="file"
                                        onChange={handleFileChange}
                                        name="image"
                                        multiple
                                        required
                                        accept="image/jpeg, image/jpg"
                                      />
                                    </div>
                                  </div>
                                  
                                  <div className="form-row">
                                    <button className="btn btn-success" onClick={uploadImage}>
                                      Add
                                    </button>
                                    <button className="btn btn-secondary" type="button">
                                      Cancel
                                    </button>
                                  </div>
                                </form>
                              </div>
                            </div>}  
                            </div>
                              </div>
                              
                              <ul className="pending-pic edit-event-pic stn-photo-edit">
                              {imgArry && imgArry.length > 0 && imgArry.map((imgg, im)=>{
                             
                               let imggg =imgg.image ?  imgg.image.replace("/images/", "/thumbnails_watermark/"):''
                                    return(
                                  <li key={im}>
                                    <img src={imggg}/>
                                    <div className="editpho-icon">
                                      <NavLink to={'#'} onClick={() => removeImg(imgg.id)}>
                                        <i className="fa fa-trash" aria-hidden="true"></i>
                                      </NavLink>
                                      <NavLink to={'#'} onClick={()=>{
                                              
                                      }}>
                                        <i className="fa fa-edit" aria-hidden="true"></i>
                                      </NavLink>
                                    </div>  
                                  
                                 
                                  </li>                            
                                     
                                    )
                                  })} 
                              </ul>
                            </div>
                            }
                            <div className="col-md-12">
                            {isload && 
                                <div className="load-img">
                                  Uploading...
                                  <div className="loader"></div>
                                </div>
                            }
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 text-center mt-4 pub-btn-fixed">
                     
                        </div>
                      </div>        
                    </div>
                  </section>
                    <div className="form-row">
                    <div className="form-group col-md-6">
                        <label htmlFor="inputLanguage">Language *</label>
                          <select
                            className="form-control"
                            name="language"
                            value={inputData.language}
                            onChange={handleChange}
                          >
                            <option value="">-- Select Language --</option>
                            <option value="en">English</option>
                            <option value="hi">Hindi</option>
                          </select>
                      </div>
                      <div className="col-md-6 mb-3">
                        <div className="form-group">
                          <label htmlFor="inputTLCategory">Stringers: *</label>
                          <input type="search" ref={inputRef} placeholder="Search...." onChange={inputSData} aria-describedby="button-addon2" className="form-control border-0 bg-light" value={inputSearch}/>
                          <select className="form-control" name="stringers"  onChange={handleOnChange} value={inputData.stringers}>
                          {!inputSearch &&  strinData && strinData.length > 0 && <option value="">-- Select --</option>}
                          {inputSearch && (!strinData || strinData.length == 0) && <option value="">-- No data found --</option>}
                              {strinData && strinData.length > 0 && strinData.map((sData, s)=>{
                                  return(
                                      <>
                                      <option value={sData.id} key={s}>{sData.name}</option>
                                      </>
                                  )
                              })}
                            
                          </select>
                        </div>
										</div>
                  </div>
                  <div className="form-row">
                      <div className="form-group col-md-12">
                          <label htmlFor="inputSlug">Remarks *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="remarks"
                            // readOnly={true}
                            value={inputData.remarks}
                            onChange={handleChange}
                            
                          />
                        </div> 
                    </div>
                    <div className="col-md-12 text-center mt-4 pub-btn-fixed">
                    {/* {  localStorage && localStorage.length > 0 && localStorage.videoOpt &&      */}
                    <button className="btn btn-primary" type="button" onClick={() => handleSubmit('pending')}>Save</button>
                   
                    {  localStorage && localStorage.length > 0 && localStorage.langg &&  localStorage.langg !='All content' &&  localStorage.langg !='ce' &&  localStorage.langg !='hindi' &&  
                         <button className="btn btn-success" type="button" onClick={() => handleSubmit('submitted')}>Submit</button>
                    }                    {/* }                   */}
                    {  localStorage && localStorage.length > 0 && isAccept &&     

                      <button className="btn btn-success" type="button" onClick={() => handleSubmit('approved')}>Approve</button>
                    }
                    {  localStorage && localStorage.length > 0 && isAccept && 
                       <button className="btn btn-warning" type="button" onClick={() => handleSubmit('hold')}>Hold</button>
                    }                    
                     {  localStorage && localStorage.length > 0 && isAccept &&     

                        <button className="btn btn-danger" type="button" onClick={() => handleSubmit(2)}>Reject</button>
                      } 
                      </div>               
                  </form>
                </div>
              </div>




              }
              {isload &&
                <div className="col-md-12">
                    <div className="load-img">
                      Loading...
                      <div className="loader"></div>
                    </div>
                </div>
              }
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  </>
  );
};

export default EditNewsStn;
