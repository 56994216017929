import React,{useState, useEffect} from 'react';
import axios from 'axios';
import Header from './Header'
import Footer from './Footer'
import {NavLink} from 'react-router-dom';
import {Helmet} from "react-helmet";
import API_URL from './ApiData'
import { format, parseISO } from 'date-fns'

const JustList =()=>{

  const [showElement, setShowElement] = useState(false);
  const [msg, setMsg] = useState('');
  const [modalData, setModalData] = useState(null);
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageList, setPageList] = useState([1,2,3]);
  const [lastPage, setLastPage] = useState(10);

  const [allStoryList, SetAllStoryData] = useState([]);

  const handleNextPage = () => {
    if(lastPage > currentPage){
      setCurrentPage(currentPage + 1);
      let newArry = pageList;
      let newAddArray = pageList[pageList.length-1]+1;
      // if(currentPage > 2){
        let newRemoveArray = pageList[0];
      //
      // }
      newArry.shift(newRemoveArray);
      newArry.push(newAddArray);
        setPageList(newArry);
        }
  }
      
     
  const handlePrevPage = () => {
  if(currentPage > 1){
    setCurrentPage(currentPage - 1);
    // setCurrentPage(currentPage + 1);
    let newArry = pageList;
    let newAddArray = pageList[pageList.length-1];
    let newRemoveArray = pageList[0]-1;
      newArry.unshift(newRemoveArray);
    newArry.pop(newAddArray);
  setPageList(newArry);
  }
  }


  const handleStartPage = () => {
  // if(requestFlag){
    setCurrentPage(1);
    let lastArrtty = [1, 2, 3]
    setPageList(lastArrtty);
  //  }
  }

  const formatDate = (timestamp)=>{
    const date = parseISO(timestamp)
    return format(date, 'MMMM dd, yyyy h:mm a')
}
  const fetchData = async (page, ln) => {
    if(currentPage <= lastPage){
      ln = ln =='ce' ? 'english' :ln; 
      let allStoryAPI =`${API_URL.JUST_LIST}?page=${currentPage}`; 
      if(inputData.searchDate){
        allStoryAPI = `${allStoryAPI}&date=${inputData.searchDate}`
      }
        axios
        .get(
            allStoryAPI
        )
        .then((res) => {
        const allStoryData =
            res.data.results &&
            res.data.results.length > 0 &&
            res.data.results.map((item) => {
            return {
                id: item.id,
                filename: item.filename,
                current_status: item.current_status,
                slug:'/'+item.slug,
                full_url:item.full_url,
                user: item.added_by,
                content : item.content,
                updated_at : item.updated_at,
                created_at: item.created_at ? formatDate(item.created_at): '',
              }
            })
            if( res.data &&
              res.data.num_pages){
                setLastPage(res.data.num_pages)
                //setPublishedByUser(res.data.data.user_edited_by);
              }
        SetAllStoryData(allStoryData);
        console.log(allStoryData, 'tesss')
        });
    }

  }

  const handleChange = event=>{
    const {name, value} =event.target;
    setInputData({ ...inputData, [name]: value })
    setCurrentPage(1)

  }
  const [inputData, setInputData] = useState({
    searchDate: ''
  })
    useEffect(() => {
      setTimeout(function () {
              if (modalData) {
          const totalCount = modalData['today\'s Count'];
          setCount(totalCount);
        }
        setTimeout(function() {
          setShowElement(false);
          }, 3000);
      if(localStorage && localStorage.uploader){
        fetchData(currentPage, localStorage.langg);
      }
    }, 800);
      }, [currentPage, modalData, msg]);
       
    return(
        <>
            <Helmet>
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
                <link rel="icon" href="icon-new.ico" type="image/x-icon" />
                <link rel="shortcut icon" href="/theme/Ianstv/images/favicon.ico" type="image/x-icon" />
                <meta data-meta-title="Ianstv.in" name="keywords" content="Ianstv news, Ianstv updates, Ianstv celebrities, Hindi cinema, Indian film industry, Ianstv gossips, Ianstv rumors, Ianstv releases, Movie reviews, Celebrity interviews" />
                <meta data-meta-title="Ianstv.in" name="description" content="Stay up-to-date with the latest Ianstv news and updates. Get the hottest gossips, rumors, movie reviews, and exclusive celebrity interviews from the captivating world of Hindi cinema. Your one-stop destination for everything Ianstv." />
            </Helmet>
            <div className="wrapper">
              <Header />
                <div className="content-wrapper">
                  <div className="content-header">
                    <div className="container-fluid">
                      <div className="row mb-2">
                        <div className="col-sm-10">
                          <h1 className="m-0">All Pictures</h1>
                        </div>
                      </div>
                    </div>
                  </div>
                  <section className="content">
                    <div className="container-fluid">
                      <div className="col-lg-12">
                        <form>
                          <div className="form-row">
                                <div className="form-group col-md-6">
                                  <label htmlFor="inputHeading">Date: *</label>
                                  <input
                                    type="date"
                                    name="searchDate"
                                    className="form-control"
                                    id="inputHeading"
                                    value={inputData.searchDate}
                                    onChange={handleChange}
                                  />
                                </div>
                                <div className="form-group col-md-6" style={{marginTop: '30px', width:'100%'}}>
                                <button className="btn btn-primary" type="button" 
                                onClick={()=>{
                                  fetchData(1,'')
                                }}
                                >Search</button>
                                </div>
                          </div>
                        </form>
                        <div className="card card-outline card-primary">
                          <div className="card-body">

                            <table className="table tabe-hover table-bordered table-compact" id="">
                              <colgroup dangerouslySetInnerHTML={{
                                  __html: `
                                 
                                  <col width="5%">
                                  <col width="60%">
                                  <col width="15%">
                                  <col width="15%">
                                  <col width="5%">
                                  `
                                }}/>
                              <thead>
                                <tr>
                                  <th>ID</th>
                                  <th>Content</th>
                                  <th>Add by</th>
                                  <th>Published Date</th>
                                  <th className='text-center'>Action</th>
                                </tr>
                              </thead>
                              <tbody>		
                                {
                                  allStoryList && allStoryList.length > 0 && allStoryList.map((allSty, c)=>{
                                
                                    return(
                                      <tr key = {c}>
                                       
                                       <td>{allSty.id}</td>
                                        <td><p dangerouslySetInnerHTML={{ __html: allSty.content.substring(0, 250)}}/></td> 
                                        <td>{allSty.user}</td>
                                        <td>{allSty.created_at}</td>         
                                        <td className="text-center">
                                          {!allSty.hindi_converted_by || !allSty.hindi_converted_by.username && <button className="btn-hindi-green btn " type="button" 
                                            
                                          > <i className="fas fa-edit"></i> Hindi
                                          </button>}
                                          <NavLink to= {'/just-edit/'+allSty.id} className="btn btn-view btn-flat btn-sm manage_service"><i className="fas fa-edit viewedit" aria-hidden="true"></i></NavLink>
                                        </td>                              
                                      </tr>
                                    )
                                  })
                                }										
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div className="pagination">
                          <ul>
                              {currentPage == 1  && <li onClick={handleStartPage} disabled={currentPage === 1}><NavLink className="prevnext disablelink" to="#">Previous</NavLink></li>}
                              {currentPage != 1  &&<li onClick={handlePrevPage} ><a href="#">Previous</a></li>}
                              {pageList.map((data, i)=>{
                              return(
                              <li key={i} onClick={()=>{
                              setCurrentPage(data)}}><a href="#" className={currentPage == data ? 'active' : ''}>{data}</a></li>
                                )
                              })}
                              <li onClick={handleNextPage}><a href="#" className="prevnext">next</a></li>
                          </ul>                                            							
                      </div>                    
                    </div>
                  </section>
                </div>
              <Footer />
            </div>
        </>
    )
}
export default JustList